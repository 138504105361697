import {ComponentRef, Injectable} from '@angular/core'
import {take} from 'rxjs/operators'
import {Monologic} from './monologic.interface'
import {Dialogistic} from './dialogistic.interface'
import {Observable} from 'rxjs'
import {Dialog} from '@angular/cdk/dialog'
import {isObject} from '../../core/utils/deep-merge'
import {GlobalPositionStrategy} from '@angular/cdk/overlay'
import {Breakpoints, BreakpointService} from '../utils/breakpoint.service'

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(
    private readonly dialog: Dialog,
    private readonly breakpointService: BreakpointService,
  ) {}

  show<Component extends Dialogistic<Data, Output> | Monologic<Data>, Data, Output>(
    component: new (...params) => Component,
    data?: DataOf<Component>,
  ): DialogContext<Component, OutputOf<Component>> {
    const mobile = !this.breakpointService.isMatched(Breakpoints.sm)
    const positionStrategy = new GlobalPositionStrategy()

    const ref = this.dialog.open(component, {
      closeOnNavigation: true,
      backdropClass: 'cft-dialog-backdrop',
      // on mobile, the position is at the bottom, full width
      // this implementation won't support resizing a window on the fly with a dialog open
      // a custom position strategy could be implemented to support that, but it wasn't obvious at this time
      positionStrategy: mobile
        ? positionStrategy.centerHorizontally().bottom()
        : positionStrategy.centerHorizontally().centerVertically(),
      ...(mobile ? {width: '100%'} : undefined),
    })

    // output
    if (isDialogistic(ref.componentInstance)) {
      ref.componentInstance
        .reply$()
        .pipe(take(1))
        .subscribe(output => {
          ref.close(output)
        })
    }

    // input
    if (isMonologic(ref.componentInstance)) {
      ref.componentInstance.setData(data as DataOf<Component>)
    }

    return {
      closed: ref.closed as Observable<OutputOf<Component>>,
      close: output => ref.close(output),
      componentRef: ref.componentRef,
    }
  }
}

export interface DialogContext<Component, Output> {
  /** Observable that emits when the dialog is closed */
  closed: Observable<Output | undefined>
  /** Closes the dialog with the given output */
  close: (output: Output | undefined) => void
  /** Reference to the component shown in the dialog */
  componentRef: ComponentRef<Component> | null
}

// types that ensure type inference for Data from a component implementing Dialogistic or Monoligic
// based on the type of the first parameter of setData (which must be Data !)
type DataOf<Component extends Dialogistic<unknown, unknown> | Monologic<unknown>> = Parameters<Component['setData']>[0]

// types that ensure type inference for Output from a component implementing Dialogistic or Monoligic
// based on the return type of reply$ (which must be Observable<Output> !)
// when the component implements Monologic, return type is never
type OutputOf<Component extends Dialogistic<unknown, unknown> | Monologic<unknown>> = Component extends Dialogistic<
  unknown,
  unknown
>
  ? ReturnType<Component['reply$']> extends Observable<infer Output>
    ? Output
    : never
  : never

function isDialogistic<Data, Output>(component: unknown): component is Dialogistic<Data, Output> {
  return !!component && isObject(component) && 'reply$' in component
}

function isMonologic<Data>(component: unknown): component is Monologic<Data> {
  return !!component && isObject(component) && 'setData' in component
}
