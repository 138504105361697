<div
  class="max-h-[90vh] w-full space-y-xl overflow-auto rounded-t-3 bg-white shadow-lg sm:rounded-3"
  [ngClass]="{
    'p-md sm:w-[368px]': size() === 'sm',
    'p-lg sm:w-[560px]': size() === 'md',
    'px-xl py-lg md:w-[752px]': size() === 'lg'
  }"
>
  <div class="flex items-end justify-between rounded-t-3">
    <span class="heading-md">{{ title() }}</span>
    <button (click)="close()" class="btn btn-icon" role="button">
      <fa-icon [icon]="faTimes" size="lg"></fa-icon>
    </button>
  </div>
  <div>
    <ng-content></ng-content>
  </div>
</div>
