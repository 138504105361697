import {Component, EventEmitter, inject, input, OnDestroy, OnInit, Output} from '@angular/core'
import {NavigationStart, Router} from '@angular/router'
import {filter, takeUntil} from 'rxjs/operators'
import {Subject} from 'rxjs'
import {faTimes} from '@fortawesome/pro-light-svg-icons/faTimes'
import {DialogRef} from '@angular/cdk/dialog'

@Component({
  selector: 'cft-dialog',
  templateUrl: './dialog.component.html',
})
export class DialogComponent<Output> implements OnInit, OnDestroy {
  readonly title = input<string | undefined>()
  readonly size = input<'sm' | 'md' | 'lg'>('md')

  @Output() readonly closed = new EventEmitter<Output | undefined>()

  private readonly router = inject(Router)
  private readonly dialogRef? = inject(DialogRef, {optional: true})

  readonly faTimes = faTimes
  private readonly unsubscribe$ = new Subject<void>()

  close(data?: Output): void {
    this.closed.emit(data)
    this.dialogRef?.close(data)
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  ngOnInit(): void {
    // close on route changes
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.close()
      })
  }
}
