/**
 * Simple is object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item: unknown): item is object {
  return !!item && typeof item === 'object' && !Array.isArray(item)
}

/**
 * Deep merge two objects.  https://gist.github.com/Salakar/1d7137de9cb8b704e48a
 * @param target
 * @param source
 */
export function deepMerge<T>(target: Partial<T>, source: Partial<T>): Partial<T> {
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, {[key]: {}})
        deepMerge(target[key], source[key])
      } else {
        Object.assign(target, {[key]: source[key]})
      }
    })
  }
  return target
}
